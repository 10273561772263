<template>
  <div>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <b-tab title="Customer Report">
        <b-card>
          <b-tabs>
            <b-tab title="Per Customer">
              <b-row>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Data Source">
                    <v-select
                      v-model="perCustomer.source"
                      :options="dataSource"
                      :clearable="false"
                      placeholder="Pilih Data Source"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Range Tanggal">
                    <date-range-picker
                      v-model="perCustomer.date"
                      :custom-range="range()"
                      :max-date="new Date()"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Sortir Berdasarkan">
                    <v-select
                      v-model="perCustomer.sort"
                      label="label"
                      :options="sortOptions"
                      placeholder="Sortir"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Dengan Grafik">
                    <b-form-checkbox
                      v-model="perCustomer.withGraph"
                      switch
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                  offset-lg="6"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    variant="primary"
                    @click="showReport(perCustomer, 'C', false)"
                  >
                    Generate
                  </b-button>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Rincian Per Customer">
              <b-row>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Data Source">
                    <v-select
                      v-model="perDetailCustomer.source"
                      :options="dataSource"
                      :clearable="false"
                      placeholder="Pilih Data Source"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Range Tanggal">
                    <date-range-picker
                      v-model="perDetailCustomer.date"
                      :custom-range="range()"
                      :max-date="new Date()"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Sortir Berdasarkan">
                    <v-select
                      v-model="perDetailCustomer.sort"
                      label="label"
                      :options="sortOptions"
                      placeholder="Sortir"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Dengan Grafik">
                    <b-form-checkbox
                      v-model="perDetailCustomer.withGraph"
                      switch
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                  offset-lg="6"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    variant="primary"
                    @click="showReport(perDetailCustomer, 'C', true)"
                  >
                    Generate
                  </b-button>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-tab>
      <b-tab title="Item Report">
        <b-card>
          <b-tabs>
            <b-tab title="Per Item">
              <b-row>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Data Source">
                    <v-select
                      v-model="perItem.source"
                      :options="dataSource"
                      :clearable="false"
                      placeholder="Pilih Data Source"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Range Tanggal">
                    <date-range-picker
                      v-model="perItem.date"
                      :custom-range="range()"
                      :max-date="new Date()"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Sortir Berdasarkan">
                    <v-select
                      v-model="perItem.sort"
                      label="label"
                      :options="sortOptions"
                      placeholder="Sortir"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Dengan Grafik">
                    <b-form-checkbox
                      v-model="perItem.withGraph"
                      switch
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                  offset-lg="6"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    variant="primary"
                    @click="showReport(perItem, 'I', false)"
                  >
                    Generate
                  </b-button>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Rincian Per Item">
              <b-row>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Data Source">
                    <v-select
                      v-model="perDetailItem.source"
                      :options="dataSource"
                      :clearable="false"
                      placeholder="Pilih Data Source"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Range Tanggal">
                    <date-range-picker
                      v-model="perDetailItem.date"
                      :custom-range="range()"
                      :max-date="new Date()"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Sortir Berdasarkan">
                    <v-select
                      v-model="perDetailItem.sort"
                      label="label"
                      :options="sortOptions"
                      placeholder="Sortir"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Dengan Grafik">
                    <b-form-checkbox
                      v-model="perDetailItem.withGraph"
                      switch
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                  offset-lg="6"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    variant="primary"
                    @click="showReport(perDetailItem, 'I', true)"
                  >
                    Generate
                  </b-button>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-tab>
      <b-tab title="User Report">
        <b-card>
          <b-tabs>
            <b-tab title="Per User">
              <b-row>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Data Source">
                    <v-select
                      v-model="perUser.source"
                      :options="dataSource"
                      :clearable="false"
                      placeholder="Pilih Data Source"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Range Tanggal">
                    <date-range-picker
                      v-model="perUser.date"
                      :custom-range="range()"
                      :max-date="new Date()"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Sortir Berdasarkan">
                    <v-select
                      v-model="perUser.sort"
                      label="label"
                      :options="sortOptions"
                      placeholder="Sortir"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Dengan Grafik">
                    <b-form-checkbox
                      v-model="perUser.withGraph"
                      switch
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                  offset-lg="6"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    variant="primary"
                    @click="showReport(perUser, 'U', false)"
                  >
                    Generate
                  </b-button>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Rincian Per User">
              <b-row>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Data Source">
                    <v-select
                      v-model="perDetailUser.source"
                      :options="dataSource"
                      :clearable="false"
                      placeholder="Pilih Data Source"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Range Tanggal">
                    <date-range-picker
                      v-model="perDetailUser.date"
                      :custom-range="range()"
                      :max-date="new Date()"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Sortir Berdasarkan">
                    <v-select
                      v-model="perDetailUser.sort"
                      label="label"
                      :options="sortOptions"
                      placeholder="Sortir"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group label="Dengan Grafik">
                    <b-form-checkbox
                      v-model="perDetailUser.withGraph"
                      switch
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                  offset-lg="6"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    variant="primary"
                    @click="showReport(perDetailUser, 'U', true)"
                  >
                    Generate
                  </b-button>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-tab>
      <b-tab title="Quotation Report">
        <b-card>
          <b-row>
            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group label="Status">
                <v-select
                  v-model="quotation.status"
                  multiple
                  label="label"
                  :options="quotation.statusOptions"
                  placeholder="Pilih status"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group label="Sortir Berdasarkan">
                <v-select
                  v-model="quotation.sort"
                  label="label"
                  :options="quotation.sortOptions"
                  placeholder="Sortir"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group label="Dibuat Oleh">
                <v-select
                  v-model="quotation.users"
                  multiple
                  label="label"
                  :options="quotation.usersOptions"
                  placeholder="Pilih user"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group label="Grup Berdasarkan">
                <v-select
                  v-model="quotation.group"
                  :options="quotation.groupOptions"
                  :clearable="false"
                  placeholder="Pilih Grup"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group label="Range Tanggal">
                <date-range-picker
                  v-model="quotation.date"
                  :custom-range="range()"
                  :max-date="new Date()"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              lg="6"
              offset-lg="6"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                variant="primary"
                @click="showQuotationReport(quotation)"
              >
                Generate
              </b-button>
            </b-col>

          </b-row>
        </b-card>
      </b-tab>
    </b-tabs>

    <pdf-preview
      ref="pdf"
    />
  </div>
</template>

<script>
import {
  BCard, BTabs, BTab, BRow, BCol, BFormGroup, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import DateRangePicker from '@/components/input/DateRangePicker.vue'
import vSelect from 'vue-select'
import PdfPreview from '@/components/misc/PdfPreview.vue'
import moment from 'moment'
import errorModal from '@/store/error-handling'
import { apolloClient } from '@/vue-apollo'
import store from '@/store'
import { quoteStatuses, users } from '@/graphql/query'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormCheckbox,
    DateRangePicker,
    vSelect,
    PdfPreview,
  },
  data() {
    return {
      quotation: {
        statusOptions: [],
        status: [],
        usersOptions: [],
        users: [],
        groupOptions: [
          {
            label: 'Status',
            value: 'S',
          },
          {
            label: 'Dibuat Oleh',
            value: 'CB',
          },
        ],
        group: {
          label: 'Status',
          value: 'S',
        },
        date: {
          startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
          endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0, 11, 59, 59, 999),
        },
        sortOptions: [
          {
            label: 'Nama Pelanggan ↑',
            field: 'customer_name',
            sortType: 'desc',
          },
          {
            label: 'Nama Pelanggan ↓',
            field: 'customer_name',
            sortType: 'asc',
          },
          {
            label: 'Nama Project ↑',
            field: 'project_name',
            sortType: 'desc',
          },
          {
            label: 'Nama Project ↓',
            field: 'project_name',
            sortType: 'asc',
          },
          {
            label: 'Total ↑',
            field: 'total',
            sortType: 'desc',
          },
          {
            label: 'Total ↓',
            field: 'total',
            sortType: 'asc',
          },
          {
            label: 'Total Volume ↑',
            field: 'total_volume',
            sortType: 'desc',
          },
          {
            label: 'Total Volume ↓',
            field: 'total_volume',
            sortType: 'asc',
          },
          {
            label: 'Waktu Dibuat ↑',
            field: 'created_at',
            sortType: 'desc',
          },
          {
            label: 'Waktu Dibuat ↓',
            field: 'created_at',
            sortType: 'asc',
          },
        ],
        sort: {
          label: 'Nama Pelanggan ↓',
          field: 'customer_name',
          sortType: 'asc',
        },
      },
      dataSource: [
        { label: 'Quote', value: 'Q' },
        { label: 'Sales Order', value: 'SO' },
        { label: 'Sales Invoice', value: 'SI' },
      ],
      sortOptions: [
        {
          label: 'Nama ↓',
          field: 'name',
          sortType: 'asc',
        },
        {
          label: 'Nama ↑',
          field: 'name',
          sortType: 'desc',
        },
        {
          label: 'Total Penjualan ↓',
          field: 'total',
          sortType: 'asc',
        },
        {
          label: 'Total Penjualan ↑',
          field: 'total',
          sortType: 'desc',
        },
      ],
      perCustomer: {
        source: { label: 'Quote', value: 'Q' },
        date: {
          startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
          endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0, 11, 59, 59, 999),
        },
        sort: {
          label: 'Total Penjualan ↑',
          field: 'total',
          sortType: 'desc',
        },
        withGraph: false,
      },
      perDetailCustomer: {
        source: { label: 'Quote', value: 'Q' },
        date: {
          startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
          endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0, 11, 59, 59, 999),
        },
        sort: {
          label: 'Total Penjualan ↑',
          field: 'total',
          sortType: 'desc',
        },
        withGraph: false,
      },
      perUser: {
        source: { label: 'Quote', value: 'Q' },
        date: {
          startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
          endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0, 11, 59, 59, 999),
        },
        sort: {
          label: 'Total Penjualan ↑',
          field: 'total',
          sortType: 'desc',
        },
        withGraph: false,
      },
      perDetailUser: {
        source: { label: 'Quote', value: 'Q' },
        date: {
          startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
          endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0, 11, 59, 59, 999),
        },
        sort: {
          label: 'Total Penjualan ↑',
          field: 'total',
          sortType: 'desc',
        },
        withGraph: false,
      },
      perItem: {
        source: { label: 'Quote', value: 'Q' },
        date: {
          startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
          endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0, 11, 59, 59, 999),
        },
        sort: {
          label: 'Total Penjualan ↑',
          field: 'total',
          sortType: 'desc',
        },
        withGraph: false,
      },
      perDetailItem: {
        source: { label: 'Quote', value: 'Q' },
        date: {
          startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
          endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0, 11, 59, 59, 999),
        },
        sort: {
          label: 'Total Penjualan ↑',
          field: 'total',
          sortType: 'desc',
        },
        withGraph: false,
      },
    }
  },
  computed: {
    ref_db() {
      return this.$store.state.reference.selectedDB.id
    },
  },
  mounted() {
    this.fetchUser()
    this.fetchQuoteStatus()
  },
  methods: {
    async fetchQuoteStatus() {
      apolloClient.query({
        query: quoteStatuses,
        context: {
          headers: {
            Authorization: store.getters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        this.quotation.statusOptions = result.data.quoteStatuses.map(user => ({ label: user.name, value: user.id }))
        console.log(this.quotation.statusOptions)
      }).catch(err => {
        errorModal(err)
      })
    },
    async fetchUser() {
      apolloClient.query({
        query: users,
        context: {
          headers: {
            Authorization: store.getters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        this.quotation.usersOptions = result.data.users.users.map(user => ({ label: user.name, value: user.id }))
      }).catch(err => {
        errorModal(err)
      })
    },
    range() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const quarter = Math.floor((today.getMonth() + 3) / 3)
      const semester = Math.floor((today.getMonth() + 6) / 6)

      let prevQuarter
      if (quarter === 1) {
        prevQuarter = new Date(today.getFullYear() - 1, 9, 1)
      } else {
        prevQuarter = new Date(today.getFullYear(), (quarter - 2) * 3, 1)
      }
      const prevQuarterEnd = new Date(prevQuarter.getFullYear(), prevQuarter.getMonth() + 3, 0, 11, 59, 59, 999)
      const thisQuarter = new Date(today.getFullYear(), (quarter - 1) * 3, 1)
      const thisQuarterEnd = new Date(today.getFullYear(), quarter * 3, 0)

      let prevSemester
      if (semester === 1) {
        prevSemester = new Date(today.getFullYear() - 1, 6, 1)
      } else {
        prevSemester = new Date(today.getFullYear(), semester - 2, 1)
      }
      const prevSemesterEnd = new Date(prevSemester.getFullYear(), prevSemester.getMonth() + 6, 0, 11, 59, 59)
      const thisSemester = new Date(today.getFullYear(), (semester - 1) * 6, 1)
      const thisSemesterEnd = new Date(today.getFullYear(), semester * 6, 0)

      /* eslint-disable quote-props */
      return {
        'Bulan lalu': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999)],
        'Triwulan Lalu': [prevQuarter, prevQuarterEnd],
        'Semester Lalu': [prevSemester, prevSemesterEnd],
        'Triwulan Ini': [thisQuarter, thisQuarterEnd],
        'Semester Ini': [thisSemester, thisSemesterEnd],
      }
    },
    showReport(data, type, detail) {
      const startDate = moment(new Date(data.date.startDate)).format('DD/MM/YYYY')
      const endDate = moment(new Date(data.date.endDate)).format('DD/MM/YYYY')
      const sortby = data.sort.field
      // eslint-disable-next-line prefer-destructuring
      const sortType = data.sort.sortType
      const source = data.source.value
      const graph = data.withGraph
      this.$refs.pdf.show(`${this.$restHTTP}/rest/reportPenjualan?ref_db=${this.ref_db}&type=${type}&data_source=${source}&date_min=${startDate}&date_max=${endDate}&monthlyGraph=${graph}&detail=${detail}&sort=${sortby}|${sortType}`)
    },
    showQuotationReport(data) {
      console.log(data)
      const group = data.group.value
      const createdBy = data.users.map(v => v.value)
      const {
        field,
        sortType,
      } = data.sort
      const status = data.status.map(v => v.value)
      console.log(status, createdBy)
      const startDate = moment(new Date(data.date.startDate)).format('DD/MM/YYYY')
      const endDate = moment(new Date(data.date.endDate)).format('DD/MM/YYYY')
      this.$refs.pdf.show(`${this.$restHTTP}/rest/reportQuotation?ref_db=${this.ref_db}&status=[${status}]&created_by=[${createdBy}]&date_min=${startDate}&date_max=${endDate}&grouped_by=${group}&sort=${field}|${sortType}`)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
